import { ReactElement, useEffect, useState } from "react";
import LogoIcon from "./LogoIcon";
import bigLogo from "./media/aryanoir_logo.png";
import MenuDropdown from "./MenuDropdown";

const MobileHeader = (): ReactElement => {
  const [position, setPosition] = useState<number>(0);
  const [goingDown, setGoingDown] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const handlePosition = (): void => {
      if (position > window.scrollY) {
        setGoingDown(false);
      } else {
        setGoingDown(true);
      }
      setPosition(window.scrollY);
    }
    window.addEventListener('scroll', handlePosition, false);

    return (): void => {
      window.removeEventListener('scroll', handlePosition, false);
    }
  }, [position])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen])
  
  return (
    <header>
      <nav style={goingDown && position > 200 ? {opacity: 0} : position > 200 ? {background: '#151B37', padding: '1rem 3rem'} : {}}>
        <LogoIcon size={30} />
        <label htmlFor="check">
          <input type="checkbox" id="check" checked={isOpen} onChange={() => setOpen(old => !old)} /> 
          <span></span>
          <span></span>
          <span></span>
        </label>
      </nav>
      {isOpen ?
        <MenuDropdown setOpen={setOpen} />
        :
        null
      }
      <div className="hero_image">
        <img src={bigLogo} alt="Aryanoir consulting" width={300} />
      </div>
    </header>
  )
}

export default MobileHeader;
