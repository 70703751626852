import { FC, ReactElement, useRef, useState } from "react";

const CardElement: FC<{title: string, description: string, image: string}> = ({title, description, image}): ReactElement => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const ref = useRef<HTMLParagraphElement>(null);

  const parStyle = {
    transition: 'height 0.35s ease-in-out',
    height: 0,
    overflow: 'hidden'
  }

  return (
    <div>
      <div
        className="card_element"
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        style={{ background: `center no-repeat url("./${image}")`, backgroundSize: 'cover'}}
      >
        <h2 className="card_title" dangerouslySetInnerHTML={{__html: title}} />
        <div style={isOpen ? {...parStyle, height: `${ref.current?.clientHeight}px`} : {...parStyle}}>
          <p ref={ref} style={{fontSize: '1.15rem', color: 'var(--beige)'}}>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default CardElement;