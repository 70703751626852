import { ReactElement, useEffect, useState } from "react";
import Logo from "./Logo";
import bigLogo from "./media/aryanoir_logo.png";

const Header = (): ReactElement => {
  const [position, setPosition] = useState<number>(0);
  const [goingDown, setGoingDown] = useState<boolean>(false);

  useEffect(() => {
    const handlePosition = (): void => {
      if (position > window.scrollY) {
        setGoingDown(false);
      } else {
        setGoingDown(true);
      }
      setPosition(window.scrollY);
    }
    window.addEventListener('scroll', handlePosition, false);

    return (): void => {
      window.removeEventListener('scroll', handlePosition, false);
    }
  }, [position])

  return (
    <header>
      <nav style={goingDown && position > 200 ? {opacity: 0} : position > 200 ? {background: '#151B37', padding: '1rem 3rem'} : {}}>
        <Logo />
        <ul className="menu">
          <li><a href="#company">Company</a></li>
          <li><a href="#values">Our values</a></li>
          <li><a href="#expertise">Fields of expertise</a></li>
          <li><a href="#about">About us</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>
      <div className="hero_image">
        <img src={bigLogo} alt="Aryanoir consulting" width={300} />
      </div>
    </header>
  )
}

export default Header;