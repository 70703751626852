import { FC, ReactElement } from "react";

const LogoIcon: FC<{size: number}> = ({size}): ReactElement => {
  return (
    <svg width="66" height="62" viewBox="0 0 66 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_99)">
      <path d="M41.1325 61.3009V45.6584L45.4325 49.6809V59.9747C44.0338 60.5093 42.597 60.9525 41.1325 61.3009ZM41.1325 32.1749V19.6171L40.4964 19.2705C38.2185 18.0348 35.632 17.384 32.9987 17.384C30.3659 17.3835 27.7798 18.0345 25.5028 19.271L24.8674 19.6168V61.3009C23.403 60.9524 21.9662 60.5092 20.5674 59.9745V2.7817C22.4523 2.06133 24.405 1.50736 26.4001 1.12702C30.756 0.305051 35.2436 0.304987 39.5995 1.12685C41.5947 1.50719 43.5476 2.06123 45.4325 2.7817V36.1976L41.1325 32.1749ZM41.1325 14.9516V5.61935L40.1743 5.3882C35.4684 4.25415 30.5321 4.25415 25.8262 5.3882L24.8674 5.61935V14.9516L26.5734 14.3842C28.6363 13.6988 30.8096 13.3497 32.9987 13.3522C35.1887 13.3496 37.3629 13.6987 39.4266 14.3842L41.1325 14.9516ZM17.2741 13.7391L11.2873 8.15033C9.89047 9.29502 8.59802 10.5466 7.42333 11.892C2.62974 17.3973 0.00839292 24.2787 -1.52588e-05 31.3792C0.00841026 38.48 2.6299 45.3616 7.4237 50.8671C7.86014 51.3671 8.31778 51.8588 8.79076 52.3364L11.8389 49.4849C11.304 48.938 10.7915 48.3693 10.308 47.7857C6.40272 43.0987 4.29072 37.3218 4.30982 31.3792C4.28996 25.4365 6.40205 19.6594 10.308 14.9728L11.1962 13.9004L17.2741 19.5745V13.7391ZM58.5734 50.8674L58.574 50.8667C63.3694 45.3621 65.9918 38.4804 66 31.3792C65.9916 24.2784 63.3701 17.3969 58.5763 11.8915C58.1399 11.3908 57.6826 10.8988 57.2099 10.4216L54.162 13.2728C54.6967 13.82 55.2092 14.3892 55.6926 14.9733C59.5982 19.6598 61.7102 25.4368 61.6902 31.3792C61.7093 37.3218 59.5974 43.0986 55.6922 47.7855L54.8038 48.8581L27.9658 23.7636V29.599L54.7108 54.6087C56.1079 53.4648 57.4 52.2134 58.5734 50.8674H58.5734Z" fill="#EFE9E1"/>
      </g>
      <defs>
      <clipPath id="clip0_2_99">
      <rect width="66" height="60.7904" fill="white" transform="translate(0 0.510498)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default LogoIcon;