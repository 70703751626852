import { FC, ReactElement } from "react";

const MailIcon: FC<{size: number}> = ({size}): ReactElement => {
  return (
    <svg id="Layer_1" style={{width: `${size}%`}} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 28.34646 28.34646">
      <defs>
        <linearGradient id="linear-gradient" x1="5.11085" y1="23.23606" x2="23.24047" y2="5.10644" gradientUnits="userSpaceOnUse">
          <stop offset="0.00562" stopColor="#efc981"/>
          <stop offset="0.18539" stopColor="#e6c081"/>
          <stop offset="0.59551" stopColor="#c58511"/>
          <stop offset="0.81057" stopColor="#e5b76d"/>
          <stop offset="0.87079" stopColor="#eec687"/>
        </linearGradient>
      </defs>
      <path style={{fill: 'url(#linear-gradient)'}} d="M25.20014,21.169a.39046.39046,0,0,0,.01362-.06922V7.24574a.39407.39407,0,0,0-.01172-.05945.26148.26148,0,0,0-.0567-.15106.37225.37225,0,0,0-.02778-.04975l-.01226-.0097-.00922-.01373a73.59106,73.59106,0,0,1-.11786-.07862,168.52111,168.52111,0,0,1-.13458-.03759c-.006-.00074-.01111.00219-.01709.00177l-.01367-.00275H3.53407l-.01587.00323a.4.4,0,0,0-.08148.01648.39755.39755,0,0,0-.06958.01923.3998.3998,0,0,0-.05939.04.39073.39073,0,0,0-.06616.05169L3.22938,6.985A.37017.37017,0,0,0,3.202,7.034a.27185.27185,0,0,0-.05774.1546.37511.37511,0,0,0-.0116.05719v13.854a.388.388,0,0,0,.0224.11041c.0025.00855-.002.01734.001.02582l.00567.00751a.39487.39487,0,0,0,.125.16443.363.363,0,0,0,.04126.02783.38909.38909,0,0,0,.19556.06323l.00653.00214.01416.00049.00141-.00049H24.81532c.00574-.00006.01056-.00342.01623-.00372a.40037.40037,0,0,0,.1446-.03327,147.527,147.527,0,0,1,.11993-.08069c.00513-.00506.0069-.01214.01172-.01745l.01025-.00794a.3859.3859,0,0,0,.02375-.04254.39036.39036,0,0,0,.04559-.08179A.39455.39455,0,0,0,25.20014,21.169Zm-9.50933-6.61414a2.37188,2.37188,0,0,1-3.0376-.001L4.61647,7.64711h19.113ZM11.048,14.23347,3.93544,20.23524V8.1208Zm.61695.53021.46484.39954a3.11525,3.11525,0,0,0,2.04151.76221h.002a3.118,3.118,0,0,0,2.04053-.76172l.46685-.40113,7.037,5.93628H4.64094Zm5.6325-.53106L24.412,8.11982v12.116Z"/>
    </svg>
  )
}

export default MailIcon;