import { ReactElement, useRef, useState, useEffect} from "react";
import MailIcon from "./MailIcon";
import PhoneIcon from "./PhoneIcon";
import PinIcon from "./PinIcon";

const Touch = (): ReactElement => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleWidth = () => {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleWidth, false);

    return (): void => {
      window.removeEventListener('resize', handleWidth, false);
    }
  }, [])

  return (
    <div className="light_bg" style={{background: 'var(--beige)', padding: '5rem 0 3rem'}} id="contact">
      <div className="light_bg_inside" style={{maxWidth: '80rem'}}>
        <div className={"title_element right_element"} ref={titleRef}>
          <h2 className={"gold right_align reversed" }>Contact</h2>
        </div>
        <div className="par_element" style={{maxWidth: '60rem', textAlign: 'end'}}>
          <div className="contact" >
            <p >Office No. 275B Building No.280 {width > 1020 ? <br /> : "" } Taweelah, Abu Dhabi, United Arab Emirates</p>
            <PinIcon size={width > 1020 ? 10 : 100} />
          </div>
          <div className="contact" >
            <p >aaa@aryanoir.com<br />mia@aryanoir.com</p>
            <MailIcon size={width > 1020 ? 10 : 100} />
          </div>
          <div className="contact" >
            <p>+971 52 249 1049<br />+971 56 455 551</p>
            <PhoneIcon size={width > 1020 ? 10 : 100} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Touch;
