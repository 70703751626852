import {  FC, ReactElement, useEffect, useRef, useState } from "react";

type ValueObject = {
  title: string,
  description: string
}

const CenterBlock: FC<{id?: string, italicTitle: string, mainTitle: string, align: string,  content?: string, arr?: ValueObject[], height?: string, reverse?: boolean }> = ({ reverse, id, italicTitle, mainTitle, content, align, arr, height}): ReactElement => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [titleHeight, setTitleHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleWidth = () => {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleWidth, false);

    return (): void => {
      window.removeEventListener('resize', handleWidth, false);
    }
  }, [])

  useEffect(() => {
    titleRef.current &&
    setTitleHeight(width > 1020 ? titleRef.current.clientHeight : 32);
  }, [titleRef, width])

  return (
    <div id={id} className={align === 'left' ? "dark_bg" : "light_bg"} style={align === 'left' ? (height && height === 'unset' ? {background: 'center no-repeat url("./aryanoir_photo_2.webp")', height: 'initial', padding: '5rem 0 3rem'} : {}) : {background: 'var(--beige) right no-repeat url("./mask_bg_right.png")', height: '31.25rem'}}>
      <div className={align === 'left' ? "dark_bg_inside" : "light_bg_inside"}>
        <div className={align === 'left' ? "title_element" : "title_element right_element"} ref={titleRef}>
          {!reverse ?
            <>
              <h2 className={align === "left" ? "" : "dark_title"}>{italicTitle}</h2>
              <h2 className={align === "left" ? "gold" : "gold right_align" }>{mainTitle}</h2>
            </>
            :
            <>
              <h2 className={align === "left" ? "gold reversed" : "gold right_align reversed" }>{mainTitle}</h2>
              <h2 className={align === "left" ? "reversed_italic" : "dark_title reversed_italic"}>{italicTitle}</h2>
            </>
          }
        </div>
        {content ?
          <div className={align === 'left' ? "par_element block_element" : "par_element block_element left_align"} style={height && height === 'unset' ? {marginTop: `${titleHeight}px`} : align === 'left' ? {marginTop: `${titleHeight}px`} : {}}>
            <p style={align === 'left' ? {} : {fontSize: '1.375rem'}} dangerouslySetInnerHTML={{__html: content}} />
          </div>
          : null
        }
        {arr && arr.length > 0 ?
          <div className={align === 'left' ? "par_element block_element" : "par_element block_element left_align"} style={align === 'left' ? {marginTop: `${titleHeight}px`} : {}}>
            {arr.map((v, i) => (
              <div className="par_array" key={i} style={{marginBottom: '3rem'}}>
                <h3>{v.title}</h3>
                <p>{v.description}</p>
              </div>
            ))}
          </div>
          : null
        }
      </div>
    </div>
  )
}

export default CenterBlock;