import CardElement from "./CardElement";
import CenterBlock from "./CenterBlock";
import Header from "./Header";
import LightBlock from "./LightBlock";
import Touch from "./Touch";
import Logo from "./Logo";
import { useEffect, useState } from "react";
import MobileHeader from "./MobileHeader";

function App() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleWidth = () => {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleWidth, false);

    return (): void => {
      window.removeEventListener('resize', handleWidth, false);
    }
  }, [])

  return (
    <div className="app">
      {width > 1020 ? <Header /> : <MobileHeader />}
      <main>
        <LightBlock />
        <CenterBlock
          mainTitle="mission"
          italicTitle="statement"
          content="Strive to be transparent, fully complied and an accountable business with uncompromising Integrity and Discipline."
          align="left"
          reverse={true}
        />
        <CenterBlock
          mainTitle="vision"
          italicTitle="statement"
          content="To remain as a solution oriented business that simplifies the life of Clients with ever evolving service offerings through our rapidly increasing global Partner Network."
          align="right"
          reverse={true}
        />
        <CenterBlock
          mainTitle="values"
          italicTitle="Our"
          arr={[
            {title: "Reliable", description: ""},
            {title: "Relevant", description: ""},
            {title: "Resourceful", description: ""},            
          ]}
          align="left"
          height="unset"
          id="values"
        />
        <div id="expertise" className="card_frame">
          <div style={{display: 'flex', justifyContent: 'center', margin: '5rem 0'}}>
            <div className="expertise_title">
              <h2 className="main_title gold">Business</h2>
              <h2 className="italic_title" style={{color: '#111'}}>segments</h2>
            </div>
          </div>
          <div className="cards">
            <CardElement
              title="Corporate<br />Advisory"
              description="We aim to provide world class end to end consultancy services and solutions for all corporate needs of each and every type of business looking to expand to or operate within the UAE market."
              image="corporate_advisory.webp"
            />
            <CardElement
              title="Real<br />Estate"
              description="Finding, sourcing and managing the best real estate investment opportunities for personal as well as rental income usage through our highly reliable dynamic network in the exponentially growing real estate market of UAE."
              image="real_estate.webp"
            />
            <CardElement
              title="Import<br />& Export"
              description="The persistent rise in crisis around the world has caused a major effect on the global supply chain market. In these times of crisis we aim to connect reputable suppliers with reliable buyers to play a fair role in aiding such businesses."
              image="import_export.webp"
            />
            <CardElement
              title="Skilled<br />Manpower"
              description="Supplying any number of professionally vetted and highly skilled manpower to any industry around the world while maintaining strict ethical human conduct to keep our Manpower and Clients constantly satisfied and content."
              image="skilled_manpower.webp"
            />
          </div>
        </div>
        <CenterBlock
          mainTitle="About us"
          italicTitle=""
          content="“Noir” in French means black.<div></div>Black as we know is a color of many sentiments<div></div>“Aryanoir” our brand is a true extension of Aryan’s personal expression around the values of confidence, elegance, sophistication & mystery.<div></div>Every opportunity perceived or pursued will be a reflection of a personal trait in deliverance.<div></div>Building on his foundation of  having a hospitality and culinary background, Aryan knows well how to cook up a great mix of business ingredients to find a sumptuous solution in building and growing brands and businesses."
          align="left"
          height="unset"
          id="about"
        />
        <Touch />
      </main>
      <footer>
        <Logo />
        <p style={{fontFamily: 'Jost, sans-serif', color: 'var(--beige)', fontSize: '1.15rem'}}>Copyright © Aryanoir {new Date().getFullYear()}</p>
      </footer>
    </div>
  );
}

export default App;
