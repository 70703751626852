import { FC, ReactElement, Dispatch, SetStateAction } from "react";

const MenuDropdown: FC<{setOpen: Dispatch<SetStateAction<boolean>>}> = ({setOpen}): ReactElement => {
  return (
    <ul className="mobilemenu" style={{display: 'flex', flexDirection: 'column', position: 'fixed'}}>
      <li><a onClick={() => setOpen(old => !old)} href="#company">Company</a></li>
      <li><a onClick={() => setOpen(old => !old)} href="#values">Our values</a></li>
      <li><a onClick={() => setOpen(old => !old)} href="#expertise">Fields of expertise</a></li>
      <li><a onClick={() => setOpen(old => !old)} href="#about">About us</a></li>
      <li><a onClick={() => setOpen(old => !old)} href="#contact">Contact</a></li>
    </ul>
  )
}

export default MenuDropdown;