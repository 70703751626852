import { ReactElement } from "react";

const LightBlock = (): ReactElement => {
  return (
    <div className="light_bg primary" id="company">
      <div className="light_bg_inside" >
        <h1>
          Aryanoir is a marketing and consultancy company
        </h1>
        <p>
          founded to bring a bouquet of services globally under one umbrella to suit various requirements of corporate entities or an individual through our Esteemed Partner Network.
        </p>
        <div className="link_group">
          <a href="#expertise">Our expertise</a>
          <a href="#contact">Get in touch</a>
        </div>
      </div>
    </div>
  )
}

export default LightBlock;