import { FC, ReactElement } from "react";

const PinIcon: FC<{size: number}> = ({size}):ReactElement => {
  return (
    <svg id="Layer_1" style={{width: `${size}%`}} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 28.34646 28.34646">
      <defs>
        <linearGradient id="linear-gradient" x1="7.42159" y1="19.30085" x2="20.9939" y2="5.72854" gradientUnits="userSpaceOnUse">
          <stop offset="0.00562" stopColor="#efc981"/>
          <stop offset="0.18539" stopColor="#e6c081"/>
          <stop offset="0.59551" stopColor="#c58511"/>
          <stop offset="0.81057" stopColor="#e5b76d"/>
          <stop offset="0.87079" stopColor="#eec687"/>
        </linearGradient>
      </defs>
      <path className="cls-1" style={{fill: 'url(#linear-gradient'}} d="M14.23122,26.11048l-.30712-.3916A46.66068,46.66068,0,0,1,7.23855,14.77943a19.52285,19.52285,0,0,1-1.04346-3.41895L6.157,10.26771A8.04084,8.04084,0,0,1,14.18874,2.236a8.0028,8.0028,0,0,1,8.00049,8.03467,8.73327,8.73327,0,0,1-.09765,1.32471c-.46289,3.95508-5.44727,11.186-7.56836,14.1123ZM14.18874,2.986A7.29,7.29,0,0,0,6.907,10.26771l.03076,1.00342a19.30015,19.30015,0,0,0,.99756,3.23047A45.19629,45.19629,0,0,0,14.20779,24.862c1.89453-2.64649,6.70849-9.67578,7.14062-13.33643a8.9281,8.9281,0,0,0,.09082-1.25781A7.25261,7.25261,0,0,0,14.18874,2.986Zm-.03125,11.06641a3.75391,3.75391,0,1,1,3.75391-3.75391A3.75785,3.75785,0,0,1,14.15749,14.05238Zm0-6.75781a3.00391,3.00391,0,1,0,3.00391,3.0039A3.007,3.007,0,0,0,14.15749,7.29457Z"/>
    </svg>
  )
}

export default PinIcon;