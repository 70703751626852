import { ReactElement } from "react";

const Logo = (): ReactElement => {
  return (
    <svg width="264" height="68" viewBox="0 0 264 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16_42)">
      <path d="M95.4365 31.3218H87.8767L86.7898 34.0092H82.7136L89.6308 16.9136H93.7073L100.625 34.0092H96.5237L95.4365 31.3218ZM94.1766 28.2112L91.6566 21.9651L89.1369 28.2112H94.1766ZM120.039 34.0092L116.16 28.4599H113.467V34.0092H109.836V16.9136H117.47C121.151 16.9136 123.868 19.3028 123.868 22.687C123.868 25.225 122.336 27.2157 120.014 28.0368L124.19 34.0092H120.039ZM113.467 25.1007H117.074C118.878 25.1007 120.113 24.1553 120.113 22.687C120.113 21.2187 118.878 20.2733 117.074 20.2733H113.467V25.1007ZM138.134 27.8377L131.489 16.9136H135.738L139.963 24.4039L144.36 16.9136H148.436L141.815 27.8377V34.0092H138.134V27.8377ZM166.709 31.3218H159.149L158.062 34.0092H153.986L160.903 16.9136H164.98L171.897 34.0092H167.796L166.709 31.3218ZM165.449 28.2112L162.929 21.9651L160.41 28.2112H165.449ZM181.177 16.9136H184.809L192.986 27.9626V16.9136H196.643V34.0092H192.986L184.809 22.9607V34.0092H181.177L181.177 16.9136ZM206.832 25.4491C206.832 20.6462 210.983 16.6148 215.85 16.6148C220.766 16.6148 224.867 20.6462 224.867 25.4491C224.867 30.2521 220.766 34.308 215.85 34.308C210.983 34.308 206.832 30.252 206.832 25.4492L206.832 25.4491ZM221.136 25.4491C221.136 22.488 218.715 19.9494 215.85 19.9494C213.009 19.9494 210.587 22.488 210.587 25.4491C210.587 28.4353 213.009 30.9734 215.85 30.9734C218.715 30.9734 221.136 28.4353 221.136 25.4492L221.136 25.4491ZM235.01 16.9136H238.666V34.0092H235.01V16.9136ZM259.849 34.0092L255.971 28.4599H253.278V34.0092H249.646V16.9136H257.28C260.961 16.9136 263.679 19.3028 263.679 22.687C263.679 25.225 262.147 27.2157 259.825 28.0368L264 34.0092H259.849ZM253.278 25.1007H256.885C258.688 25.1007 259.923 24.1553 259.923 22.687C259.923 21.2187 258.688 20.2733 256.885 20.2733H253.278V25.1007Z" fill="#EFE9E1"/>
      <path d="M83.4424 45.5105H84.4767L86.4288 49.8124L88.3808 45.5105H89.4151V51.3838H88.414V47.5695L86.6789 51.3838H86.1783L84.4432 47.5695V51.3838H83.4424V45.5105Z" fill="#EFE9E1"/>
      <path d="M94.1647 45.5105H95.191L97.5182 51.3838H96.4423L95.9082 50.0312H93.4391L92.9137 51.3838H91.8291L94.1647 45.5105ZM95.6165 49.1487L94.6737 46.6533L93.7312 49.1487H95.6165Z" fill="#EFE9E1"/>
      <path d="M99.9365 45.5105H102.372C102.912 45.4751 103.445 45.6463 103.865 45.9897C104.047 46.1688 104.188 46.3855 104.279 46.6248C104.37 46.8641 104.408 47.1204 104.391 47.376C104.403 47.7673 104.292 48.1524 104.074 48.4766C103.853 48.7865 103.536 49.014 103.173 49.1234L104.358 51.3837H103.273L102.147 49.2331H100.996V51.3837H99.9365V45.5105ZM102.18 48.3507C102.477 48.365 102.771 48.2793 103.014 48.1072C103.122 48.0201 103.207 47.9081 103.262 47.7808C103.317 47.6535 103.341 47.5146 103.332 47.376C103.332 46.7208 102.948 46.3931 102.18 46.3929H100.996V48.3507L102.18 48.3507Z" fill="#EFE9E1"/>
      <path d="M106.986 45.5105H108.046V48.1741L110.44 45.5105H111.708L109.122 48.3844L111.874 51.3838H110.606L108.046 48.5942V51.3838H106.986V45.5105Z" fill="#EFE9E1"/>
      <path d="M114.289 45.5105H118.234V46.3929H115.348V48.0059H117.492V48.8883H115.348V50.5019H118.234V51.3838H114.289V45.5105Z" fill="#EFE9E1"/>
      <path d="M122.205 46.3929H120.445V45.5105H125.024V46.3929H123.264V51.3838H122.205V46.3929Z" fill="#EFE9E1"/>
      <path d="M127.355 45.5105H128.414V51.3838H127.355V45.5105Z" fill="#EFE9E1"/>
      <path d="M131.237 45.5105H132.238L134.966 49.6026V45.5105H136.025V51.3838H135.024L132.297 47.2838V51.3838H131.237V45.5105Z" fill="#EFE9E1"/>
      <path d="M140.109 51.136C139.646 50.8965 139.263 50.5256 139.008 50.0691C138.75 49.5695 138.616 49.0147 138.616 48.4516C138.616 47.8886 138.75 47.3338 139.008 46.8341C139.264 46.377 139.647 46.0046 140.109 45.7624C140.612 45.5032 141.171 45.3734 141.736 45.3845C142.112 45.3797 142.488 45.4249 142.853 45.5189C143.175 45.6053 143.477 45.7506 143.746 45.9475V46.9643C143.501 46.7514 143.214 46.5937 142.903 46.502C142.553 46.4023 142.191 46.3542 141.827 46.3592C141.449 46.354 141.076 46.4419 140.739 46.6153C140.419 46.7813 140.151 47.0347 139.967 47.3465C139.773 47.6813 139.675 48.0637 139.684 48.4513C139.674 48.8395 139.766 49.2234 139.95 49.5646C140.123 49.8754 140.38 50.1294 140.693 50.2957C141.027 50.471 141.4 50.559 141.777 50.5519C142.143 50.5656 142.506 50.4908 142.837 50.3337V49.0143H141.853V48.1656H143.896V50.9051C143.379 51.3084 142.659 51.5099 141.736 51.5097C141.171 51.5207 140.613 51.3924 140.109 51.136Z" fill="#EFE9E1"/>
      <path d="M151.322 51.136C150.859 50.8965 150.477 50.5256 150.221 50.0691C149.963 49.5695 149.829 49.0147 149.829 48.4516C149.829 47.8886 149.963 47.3338 150.221 46.8341C150.477 46.377 150.86 46.0047 151.322 45.7624C151.897 45.483 152.533 45.3549 153.171 45.3902C153.808 45.4255 154.426 45.6231 154.967 45.9644V46.9812C154.421 46.5453 153.736 46.3243 153.041 46.3592C152.65 46.3505 152.263 46.4385 151.914 46.6153C151.599 46.7792 151.339 47.0318 151.164 47.3423C150.979 47.6819 150.887 48.0644 150.897 48.4513C150.887 48.8388 150.981 49.2218 151.168 49.5604C151.345 49.8708 151.607 50.1232 151.923 50.2873C152.269 50.4639 152.653 50.5519 153.041 50.5435C153.413 50.5491 153.785 50.4967 154.142 50.388C154.469 50.2821 154.772 50.1123 155.034 49.8883V50.9052C154.427 51.3379 153.692 51.5509 152.949 51.5097C152.384 51.5207 151.826 51.3924 151.322 51.136Z" fill="#EFE9E1"/>
      <path d="M158.809 51.1276C158.346 50.8793 157.961 50.5053 157.699 50.048C157.434 49.5584 157.295 49.0095 157.295 48.4516C157.295 47.8938 157.434 47.3448 157.699 46.8552C157.962 46.3971 158.346 46.0216 158.809 45.7709C159.296 45.517 159.837 45.3845 160.385 45.3845C160.934 45.3845 161.475 45.517 161.962 45.7709C162.425 46.0216 162.809 46.3971 163.071 46.8552C163.337 47.3441 163.476 47.8923 163.476 48.4495C163.476 49.0067 163.337 49.5549 163.071 50.0438C162.808 50.5 162.424 50.8737 161.962 51.1233C161.479 51.3844 160.938 51.5173 160.39 51.5097C159.839 51.518 159.295 51.3866 158.809 51.1276ZM161.407 50.2873C161.713 50.1165 161.966 49.8633 162.137 49.5562C162.314 49.2169 162.407 48.8395 162.408 48.4561C162.409 48.0727 162.317 47.6948 162.141 47.3549C161.971 47.047 161.72 46.7925 161.416 46.6196C161.102 46.444 160.748 46.3543 160.39 46.3592C160.03 46.3545 159.674 46.4443 159.359 46.6196C159.053 46.7915 158.8 47.0462 158.629 47.3549C158.453 47.6948 158.362 48.0727 158.363 48.4561C158.363 48.8395 158.456 49.2169 158.634 49.5562C158.806 49.8642 159.06 50.1174 159.368 50.2873C159.681 50.4595 160.033 50.5477 160.39 50.5435C160.745 50.548 161.096 50.4597 161.407 50.2873Z" fill="#EFE9E1"/>
      <path d="M166.051 45.5105H167.052L169.78 49.6026V45.5105H170.839V51.3838H169.838L167.111 47.2838V51.3838H166.051V45.5105Z" fill="#EFE9E1"/>
      <path d="M174.29 51.4133C174.003 51.3647 173.724 51.2786 173.46 51.1571V50.2409C173.739 50.376 174.033 50.4775 174.336 50.5435C174.632 50.6099 174.934 50.6438 175.237 50.6447C175.535 50.6642 175.832 50.5907 176.088 50.4344C176.181 50.3704 176.257 50.2837 176.308 50.1823C176.359 50.0809 176.384 49.9682 176.38 49.8545C176.385 49.6992 176.338 49.5465 176.246 49.4218C176.139 49.2932 176.005 49.1899 175.854 49.1192C175.682 49.0324 175.417 48.9191 175.062 48.7792C174.617 48.6205 174.213 48.3651 173.877 48.0312C173.629 47.7493 173.498 47.3827 173.51 47.0065C173.505 46.7032 173.596 46.4061 173.769 46.1578C173.952 45.9023 174.204 45.7042 174.494 45.5864C174.836 45.4461 175.202 45.3774 175.571 45.3845C175.869 45.3811 176.166 45.4079 176.459 45.4647C176.722 45.519 176.977 45.6021 177.222 45.7124V46.6027C176.766 46.3741 176.264 46.2535 175.754 46.2501C175.465 46.2327 175.178 46.3015 174.928 46.4478C174.835 46.507 174.759 46.5898 174.708 46.6879C174.657 46.7859 174.632 46.8958 174.636 47.0065C174.63 47.1594 174.677 47.3097 174.77 47.4309C174.867 47.547 174.988 47.6414 175.124 47.7081C175.271 47.7835 175.537 47.9024 175.921 48.065C176.244 48.1926 176.552 48.3532 176.842 48.5441C177.053 48.6845 177.227 48.8731 177.351 49.0945C177.472 49.332 177.531 49.5964 177.523 49.863C177.534 50.0957 177.489 50.3277 177.391 50.5392C177.294 50.7506 177.147 50.9351 176.964 51.0769C176.591 51.3656 176.04 51.5099 175.312 51.5097C174.969 51.51 174.627 51.4777 174.29 51.4133Z" fill="#EFE9E1"/>
      <path d="M180.632 50.8588C180.416 50.6223 180.249 50.3449 180.141 50.0427C180.033 49.7404 179.986 49.4195 180.002 49.0987V45.5105H181.062V49.115C181.048 49.3097 181.076 49.5052 181.143 49.6884C181.21 49.8717 181.314 50.0386 181.45 50.1782C181.747 50.4148 182.114 50.5435 182.492 50.5435C182.871 50.5435 183.238 50.4148 183.535 50.1782C183.67 50.0386 183.775 49.8717 183.842 49.6884C183.909 49.5052 183.936 49.3097 183.923 49.115V45.5105H184.983V49.0987C184.999 49.4192 184.952 49.7398 184.845 50.0419C184.738 50.3441 184.572 50.6217 184.357 50.8588C183.94 51.2927 183.32 51.5097 182.496 51.5097C181.673 51.5097 181.052 51.2927 180.632 50.8588Z" fill="#EFE9E1"/>
      <path d="M187.772 45.5105H188.831V50.5019H191.634V51.3838H187.772V45.5105Z" fill="#EFE9E1"/>
      <path d="M194.676 46.3929H192.917V45.5105H197.496V46.3929H195.736V51.3838H194.676V46.3929Z" fill="#EFE9E1"/>
      <path d="M201.197 45.5105H202.223L204.55 51.3838H203.474L202.94 50.0312H200.471L199.946 51.3838H198.861L201.197 45.5105ZM202.649 49.1487L201.706 46.6533L200.763 49.1487H202.649Z" fill="#EFE9E1"/>
      <path d="M206.969 45.5105H207.969L210.697 49.6026V45.5105H211.757V51.3838H210.756L208.028 47.2838V51.3838H206.969V45.5105Z" fill="#EFE9E1"/>
      <path d="M215.841 51.136C215.378 50.8965 214.995 50.5256 214.74 50.0691C214.482 49.5695 214.348 49.0147 214.348 48.4516C214.348 47.8886 214.482 47.3338 214.74 46.8341C214.996 46.377 215.379 46.0047 215.841 45.7624C216.416 45.483 217.052 45.3549 217.689 45.3902C218.327 45.4255 218.945 45.6231 219.486 45.9644V46.9812C218.94 46.5453 218.255 46.3243 217.559 46.3592C217.169 46.3505 216.782 46.4385 216.433 46.6153C216.118 46.7792 215.857 47.0318 215.682 47.3423C215.498 47.6819 215.406 48.0644 215.416 48.4513C215.406 48.8388 215.5 49.2218 215.687 49.5604C215.864 49.8708 216.126 50.1232 216.442 50.2873C216.788 50.4639 217.172 50.5519 217.559 50.5435C217.932 50.5491 218.304 50.4967 218.66 50.388C218.988 50.2821 219.291 50.1123 219.553 49.8883V50.9052C218.945 51.3379 218.21 51.5509 217.467 51.5097C216.903 51.5207 216.345 51.3924 215.841 51.136Z" fill="#EFE9E1"/>
      <path d="M223.877 49.1487L221.758 45.5105H222.885L224.386 48.124L225.929 45.5105H227.047L224.887 49.1487V51.3838H223.877V49.1487Z" fill="#EFE9E1"/>
      <path d="M42.7332 68V50.5023L47.2005 55.0019V66.5164C45.7473 67.1145 44.2546 67.6102 42.7332 68ZM42.7332 35.4197V21.3726L42.0723 20.9849C39.706 19.6025 37.0191 18.8746 34.2837 18.8747C31.5482 18.8748 28.8614 19.6029 26.4952 20.9854L25.8351 21.3722V68C24.3137 67.6101 22.821 67.1144 21.3678 66.5162V2.54052C23.326 1.73472 25.3547 1.11505 27.4275 0.689606C31.9529 -0.229847 36.615 -0.22991 41.1405 0.689421C43.2133 1.11488 45.2422 1.73461 47.2005 2.54052V39.9195L42.7332 35.4197ZM42.7332 16.1538V5.71474L41.7376 5.45617C36.8486 4.18762 31.7202 4.18762 26.8312 5.45617L25.8351 5.71474V16.1538L27.6075 15.5191C31.9274 13.9798 36.6409 13.9798 40.9608 15.5191L42.7332 16.1538ZM17.9462 14.7974L11.7265 8.54587C10.2753 9.82631 8.93261 11.2263 7.71222 12.7313C2.7243 18.8852 -6.77792e-05 26.5857 1.26472e-09 34.53C6.77818e-05 42.4744 2.72457 50.1748 7.71259 56.3287C8.16601 56.888 8.64147 57.438 9.13286 57.9723L12.2996 54.7826C11.7439 54.1709 11.2114 53.5347 10.7091 52.8819C6.66958 47.6294 4.47753 41.1737 4.47753 34.5297C4.47753 27.8856 6.66958 21.4299 10.7091 16.1775L11.6319 14.9779L17.9462 21.325L17.9462 14.7974ZM60.8527 56.329L60.8533 56.3283C65.8425 50.175 68.5679 42.4745 68.5683 34.5299C68.5687 26.5853 65.8442 18.8845 60.8557 12.7307C60.4023 12.1706 59.9272 11.6203 59.4362 11.0865L56.2696 14.2758C56.8251 14.8879 57.3576 15.5247 57.8598 16.178C61.899 21.4305 64.0908 27.886 64.0908 34.5299C64.0907 41.1738 61.8987 47.6293 57.8594 52.8817L56.9364 54.0815L29.0541 26.0108V32.5383L56.8398 60.5141C58.2913 59.2345 59.6336 57.8346 60.8527 56.329Z" fill="#EFE9E1"/>
      </g>
      <defs>
      <clipPath id="clip0_16_42">
      <rect width="264" height="68" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default Logo;