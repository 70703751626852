import { FC, ReactElement } from "react";

const PhoneIcon: FC<{size: number}> = ({size}): ReactElement => {
  return (
    <svg id="Layer_1" style={{width: `${size}%`}} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 28.34646 28.34646">
      <defs>
        <linearGradient id="linear-gradient" x1="9.44443" y1="18.74455" x2="16.6218" y2="11.56718" gradientUnits="userSpaceOnUse">
          <stop offset="0.00562" stopColor="#efc981"/>
          <stop offset="0.18539" stopColor="#e6c081"/>
          <stop offset="0.59551" stopColor="#c58511"/>
          <stop offset="0.81057" stopColor="#e5b76d"/>
          <stop offset="0.87079" stopColor="#eec687"/>
        </linearGradient>
      </defs>
      <path style={{fill: 'url(#linear-gradient)'}} d="M18.79221,23.71733c-2.76611.00049-6.51318-2.13818-9.29492-4.91992a19.81532,19.81532,0,0,1-4.313-6.35791c-.98633-2.47656-.90381-4.46631.23242-5.60205l1.919-1.91895a1.69457,1.69457,0,0,1,1.33447-.52783,2.3254,2.3254,0,0,1,1.63623.95752l2.38379,2.63623a2.3934,2.3934,0,0,1-.17773,3.13428l-1.27784,1.27783a.38368.38368,0,0,0-.05224.3374c.00488.00781.31836.55371.44678.74414a11.81,11.81,0,0,0,3.08349,3.0835c.18848.127.35889.23242.50879.31787l.20752.11279a.39762.39762,0,0,0,.36328-.03808l1.27832-1.27784a2.38833,2.38833,0,0,1,3.11182-.19677l2.80078,2.47851a2.08911,2.08911,0,0,1,.85986,1.49317,1.79818,1.79818,0,0,1-.57275,1.40185l-1.91846,1.919A3.48619,3.48619,0,0,1,18.79221,23.71733ZM8.5637,5.18755a.92206.92206,0,0,0-.6626.29638L5.98215,7.40288c-1.209,1.209-.55322,3.48877-.05469,4.74023A19.281,19.281,0,0,0,10.06272,18.232c3.47656,3.47607,8.73437,5.96142,10.72363,3.97461l1.91846-1.91895a1.01166,1.01166,0,0,0,.33984-.78662,1.33208,1.33208,0,0,0-.57226-.92773l-2.80078-2.47852a1.59639,1.59639,0,0,0-2.03565.147l-1.27832,1.27784a1.16251,1.16251,0,0,1-1.314.17334c-.06006-.03077-.124-.064-.21973-.11914-.16357-.09327-.35156-.20948-.55859-.34913a12.63159,12.63159,0,0,1-3.29981-3.29931c-.13721-.20362-.47266-.78613-.47266-.78613a1.147,1.147,0,0,1,.17627-1.30811L11.947,10.55327a1.597,1.597,0,0,0,.13037-2.05518L9.69358,5.86186A1.56288,1.56288,0,0,0,8.62034,5.1895C8.60129,5.188,8.58274,5.18755,8.5637,5.18755Z"/>
    </svg>
  )
}

export default PhoneIcon;